/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAdGroupFrequenciesCommand } from '../models/AddAdGroupFrequenciesCommand';
import type { AssignTrackingCodesCommand } from '../models/AssignTrackingCodesCommand';
import type { BulkResendCommand } from '../models/BulkResendCommand';
import type { DeleteAdGroupCommand } from '../models/DeleteAdGroupCommand';
import type { DeleteAdGroupCreativesCommand } from '../models/DeleteAdGroupCreativesCommand';
import type { DeleteAdgroupFlightsCommand } from '../models/DeleteAdgroupFlightsCommand';
import type { DeleteAdGroupFrequenciesCommand } from '../models/DeleteAdGroupFrequenciesCommand';
import type { DeleteCampaignCommand } from '../models/DeleteCampaignCommand';
import type { DeleteCampaignFlightsCommand } from '../models/DeleteCampaignFlightsCommand';
import type { FindBrandQuery } from '../models/FindBrandQuery';
import type { FindBrandQueryResult } from '../models/FindBrandQueryResult';
import type { GetAccountLevelStatsQuery } from '../models/GetAccountLevelStatsQuery';
import type { GetAccountLevelStatsQueryResult } from '../models/GetAccountLevelStatsQueryResult';
import type { GetAdGroupAudiencesQuery } from '../models/GetAdGroupAudiencesQuery';
import type { GetAdGroupAudiencesQueryResult } from '../models/GetAdGroupAudiencesQueryResult';
import type { GetAdGroupBrandSafetyQuery } from '../models/GetAdGroupBrandSafetyQuery';
import type { GetAdGroupBrandSafetyQueryResult } from '../models/GetAdGroupBrandSafetyQueryResult';
import type { GetAdGroupCreativesQuery } from '../models/GetAdGroupCreativesQuery';
import type { GetAdGroupCreativesQueryResult } from '../models/GetAdGroupCreativesQueryResult';
import type { GetAdGroupDaypartingQuery } from '../models/GetAdGroupDaypartingQuery';
import type { GetAdGroupDaypartingQueryResult } from '../models/GetAdGroupDaypartingQueryResult';
import type { GetAdGroupDealsQuery } from '../models/GetAdGroupDealsQuery';
import type { GetAdGroupDealsQueryResult } from '../models/GetAdGroupDealsQueryResult';
import type { GetAdGroupDetailsQuery } from '../models/GetAdGroupDetailsQuery';
import type { GetAdGroupDetailsQueryResult } from '../models/GetAdGroupDetailsQueryResult';
import type { GetAdGroupFlightsQuery } from '../models/GetAdGroupFlightsQuery';
import type { GetAdGroupFlightsQueryResult } from '../models/GetAdGroupFlightsQueryResult';
import type { GetAdGroupFrequenciesQueryResult } from '../models/GetAdGroupFrequenciesQueryResult';
import type { GetAdGroupFrequencyQuery } from '../models/GetAdGroupFrequencyQuery';
import type { GetAdGroupInventoryListsQuery } from '../models/GetAdGroupInventoryListsQuery';
import type { GetAdGroupInventoryListsQueryResult } from '../models/GetAdGroupInventoryListsQueryResult';
import type { GetAdGroupLocationsQuery } from '../models/GetAdGroupLocationsQuery';
import type { GetAdGroupLocationsQueryResult } from '../models/GetAdGroupLocationsQueryResult';
import type { GetAdGroupMediaBidStrategyQuery } from '../models/GetAdGroupMediaBidStrategyQuery';
import type { GetAdGroupMediaBidStrategyQueryResult } from '../models/GetAdGroupMediaBidStrategyQueryResult';
import type { GetAdGroupPublishersQuery } from '../models/GetAdGroupPublishersQuery';
import type { GetAdGroupPublishersQueryResult } from '../models/GetAdGroupPublishersQueryResult';
import type { GetAdGroupStatisticsCsvQuery } from '../models/GetAdGroupStatisticsCsvQuery';
import type { GetAdGroupStatisticsForCampaignCsvQuery } from '../models/GetAdGroupStatisticsForCampaignCsvQuery';
import type { GetAdGroupStatisticsForCampaignQuery } from '../models/GetAdGroupStatisticsForCampaignQuery';
import type { GetAdGroupStatisticsForCampaignQueryResult } from '../models/GetAdGroupStatisticsForCampaignQueryResult';
import type { GetAdGroupStatisticsQuery } from '../models/GetAdGroupStatisticsQuery';
import type { GetAdGroupStatisticsQueryResult } from '../models/GetAdGroupStatisticsQueryResult';
import type { GetAudiencesByCategoryQuery } from '../models/GetAudiencesByCategoryQuery';
import type { GetAudiencesByCategoryQueryResult } from '../models/GetAudiencesByCategoryQueryResult';
import type { GetCampaignByIdQuery } from '../models/GetCampaignByIdQuery';
import type { GetCampaignByIdQueryResult } from '../models/GetCampaignByIdQueryResult';
import type { GetCampaignDetailsDataQuery } from '../models/GetCampaignDetailsDataQuery';
import type { GetCampaignDetailsDataQueryResult } from '../models/GetCampaignDetailsDataQueryResult';
import type { GetCampaignOverviewDeliveryDataQuery } from '../models/GetCampaignOverviewDeliveryDataQuery';
import type { GetCampaignOverviewDeliveryDataQueryResult } from '../models/GetCampaignOverviewDeliveryDataQueryResult';
import type { GetCampaignOverviewFlightScheduleDataQuery } from '../models/GetCampaignOverviewFlightScheduleDataQuery';
import type { GetCampaignOverviewFlightScheduleDataQueryResult } from '../models/GetCampaignOverviewFlightScheduleDataQueryResult';
import type { GetCampaignOverviewLeaderboardQuery } from '../models/GetCampaignOverviewLeaderboardQuery';
import type { GetCampaignOverviewLeaderboardQueryResult } from '../models/GetCampaignOverviewLeaderboardQueryResult';
import type { GetCampaignOverviewStatsQuery } from '../models/GetCampaignOverviewStatsQuery';
import type { GetCampaignOverviewStatsQueryResult } from '../models/GetCampaignOverviewStatsQueryResult';
import type { GetCampaignsForCompetitiveSeparationQuery } from '../models/GetCampaignsForCompetitiveSeparationQuery';
import type { GetCampaignsForCompetitiveSeparationQueryResult } from '../models/GetCampaignsForCompetitiveSeparationQueryResult';
import type { GetCampaignsStatisticsCsvQuery } from '../models/GetCampaignsStatisticsCsvQuery';
import type { GetCampaignsStatisticsQuery } from '../models/GetCampaignsStatisticsQuery';
import type { GetCampaignsStatisticsQueryResult } from '../models/GetCampaignsStatisticsQueryResult';
import type { GetFeasibilityResultQuery } from '../models/GetFeasibilityResultQuery';
import type { GetFeasibilityResultQueryResult } from '../models/GetFeasibilityResultQueryResult';
import type { GetInventoryListsQuery } from '../models/GetInventoryListsQuery';
import type { GetInventoryListsQueryResult } from '../models/GetInventoryListsQueryResult';
import type { GetListOfCampaignsQuery } from '../models/GetListOfCampaignsQuery';
import type { GetListOfCampaignsQueryResult } from '../models/GetListOfCampaignsQueryResult';
import type { GetListOfCreativesAvailableForAdGroupQuery } from '../models/GetListOfCreativesAvailableForAdGroupQuery';
import type { GetListOfCreativesAvailableForAdGroupQueryResult } from '../models/GetListOfCreativesAvailableForAdGroupQueryResult';
import type { GetLocationsQuery } from '../models/GetLocationsQuery';
import type { GetLocationsQueryResult } from '../models/GetLocationsQueryResult';
import type { GetP360BrandCodesQuery } from '../models/GetP360BrandCodesQuery';
import type { GetP360BrandCodesQueryResult } from '../models/GetP360BrandCodesQueryResult';
import type { GetP360BrandsQuery } from '../models/GetP360BrandsQuery';
import type { GetP360BrandsQueryResult } from '../models/GetP360BrandsQueryResult';
import type { GetPlBrandCodesQuery } from '../models/GetPlBrandCodesQuery';
import type { GetPlBrandCodesQueryResult } from '../models/GetPlBrandCodesQueryResult';
import type { GetPlBrandsQuery } from '../models/GetPlBrandsQuery';
import type { GetPlBrandsQueryResult } from '../models/GetPlBrandsQueryResult';
import type { GetPublishersForAdGroupTargetingQuery } from '../models/GetPublishersForAdGroupTargetingQuery';
import type { GetPublishersForAdGroupTargetingQueryResult } from '../models/GetPublishersForAdGroupTargetingQueryResult';
import type { GetSourceAdGroupsQuery } from '../models/GetSourceAdGroupsQuery';
import type { GetSourceAdGroupsQueryResult } from '../models/GetSourceAdGroupsQueryResult';
import type { GetSourceAdGroupsValidateQuery } from '../models/GetSourceAdGroupsValidateQuery';
import type { GetSourceAdGroupsValidateQueryResult } from '../models/GetSourceAdGroupsValidateQueryResult';
import type { GetTherapeuticAreasQuery } from '../models/GetTherapeuticAreasQuery';
import type { GetTherapeuticAreasQueryResult } from '../models/GetTherapeuticAreasQueryResult';
import type { GetTotalCpmQuery } from '../models/GetTotalCpmQuery';
import type { GetTotalCpmQueryResult } from '../models/GetTotalCpmQueryResult';
import type { GetUserAdGroupsQuickStatsFiltersQuery } from '../models/GetUserAdGroupsQuickStatsFiltersQuery';
import type { GetUserAdGroupsQuickStatsFiltersQueryResult } from '../models/GetUserAdGroupsQuickStatsFiltersQueryResult';
import type { GetValidationMetadataQuery } from '../models/GetValidationMetadataQuery';
import type { GetValidationMetadataQueryResult } from '../models/GetValidationMetadataQueryResult';
import type { LinkAdGroupCreativesCommand } from '../models/LinkAdGroupCreativesCommand';
import type { LinkAdGroupCreativesLeversCommand } from '../models/LinkAdGroupCreativesLeversCommand';
import type { LinkAudiencesCommand } from '../models/LinkAudiencesCommand';
import type { LinkBrandSafetyCommand } from '../models/LinkBrandSafetyCommand';
import type { LinkDealsCommand } from '../models/LinkDealsCommand';
import type { LinkInventoryListsCommand } from '../models/LinkInventoryListsCommand';
import type { LinkLocationsCommand } from '../models/LinkLocationsCommand';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { PXCommandResultBase } from '../models/PXCommandResultBase';
import type { SearchAudiencesQuery } from '../models/SearchAudiencesQuery';
import type { SearchAudiencesQueryResult } from '../models/SearchAudiencesQueryResult';
import type { SetAdGroupDataCommand } from '../models/SetAdGroupDataCommand';
import type { SetAdGroupDaypartingCommand } from '../models/SetAdGroupDaypartingCommand';
import type { SetAdGroupPublishersCommand } from '../models/SetAdGroupPublishersCommand';
import type { SetCampaignDataCommand } from '../models/SetCampaignDataCommand';
import type { SetCampaignStatusesCommand } from '../models/SetCampaignStatusesCommand';
import type { SetCompetitiveSeparationsCommand } from '../models/SetCompetitiveSeparationsCommand';
import type { SetMediaBidStrategyCommand } from '../models/SetMediaBidStrategyCommand';
import type { SetUserAdGroupsQuickStatsFiltersCommand } from '../models/SetUserAdGroupsQuickStatsFiltersCommand';
import type { SwitchAdgroupChannelCommand } from '../models/SwitchAdgroupChannelCommand';
import type { SwitchAdGroupStatusesCommand } from '../models/SwitchAdGroupStatusesCommand';
import type { UpdateAdGroupCreativesCommand } from '../models/UpdateAdGroupCreativesCommand';
import type { UpdateAdGroupFrequenciesCommand } from '../models/UpdateAdGroupFrequenciesCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ActivationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSetCampaignData(
        requestBody?: SetCampaignDataCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetCampaignData',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationSetCampaignStatuses(
        requestBody?: SetCampaignStatusesCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetCampaignStatuses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSetAdGroupData(
        requestBody?: SetAdGroupDataCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetAdGroupData',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationDeleteCampaign(
        requestBody?: DeleteCampaignCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteCampaign',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationDeleteAdGroup(
        requestBody?: DeleteAdGroupCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteAdGroup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationDeleteAdgroupFlights(
        requestBody?: DeleteAdgroupFlightsCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteAdgroupFlights',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationDeleteCampaignFlights(
        requestBody?: DeleteCampaignFlightsCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteCampaignFlights',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationAssignTrackingCodes(
        requestBody?: AssignTrackingCodesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/AssignTrackingCodes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSwitchAdGroupStatuses(
        requestBody?: SwitchAdGroupStatusesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SwitchAdGroupStatuses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSwitchAdgroupChannel(
        requestBody?: SwitchAdgroupChannelCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SwitchAdgroupChannel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkAudiences(
        requestBody?: LinkAudiencesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkAudiences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkBrandSafety(
        requestBody?: LinkBrandSafetyCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkBrandSafety',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkDeals(
        requestBody?: LinkDealsCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkDeals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkInventoryLists(
        requestBody?: LinkInventoryListsCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkInventoryLists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkLocations(
        requestBody?: LinkLocationsCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkLocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignByIdQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignById(
        requestBody?: GetCampaignByIdQuery,
    ): CancelablePromise<GetCampaignByIdQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignById',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetListOfCampaignsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetListOfCampaigns(
        requestBody?: GetListOfCampaignsQuery,
    ): CancelablePromise<GetListOfCampaignsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetListOfCampaigns',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupDetailsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupDetails(
        requestBody?: GetAdGroupDetailsQuery,
    ): CancelablePromise<GetAdGroupDetailsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupDetails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupLocationsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupLocations(
        requestBody?: GetAdGroupLocationsQuery,
    ): CancelablePromise<GetAdGroupLocationsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupLocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupAudiencesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupAudiences(
        requestBody?: GetAdGroupAudiencesQuery,
    ): CancelablePromise<GetAdGroupAudiencesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupAudiences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupBrandSafetyQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupBrandSafety(
        requestBody?: GetAdGroupBrandSafetyQuery,
    ): CancelablePromise<GetAdGroupBrandSafetyQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupBrandSafety',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupDealsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupDeals(
        requestBody?: GetAdGroupDealsQuery,
    ): CancelablePromise<GetAdGroupDealsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupDeals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupFlightsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupFlights(
        requestBody?: GetAdGroupFlightsQuery,
    ): CancelablePromise<GetAdGroupFlightsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupFlights',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns FindBrandQueryResult OK
     * @throws ApiError
     */
    public postApiActivationFindBrand(
        requestBody?: FindBrandQuery,
    ): CancelablePromise<FindBrandQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/FindBrand',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationAddCompetitiveSeparations(
        requestBody?: SetCompetitiveSeparationsCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/AddCompetitiveSeparations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignsForCompetitiveSeparationQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignsForCompetitiveSeparation(
        requestBody?: GetCampaignsForCompetitiveSeparationQuery,
    ): CancelablePromise<GetCampaignsForCompetitiveSeparationQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignsForCompetitiveSeparation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param query
     * @returns GetValidationMetadataQueryResult OK
     * @throws ApiError
     */
    public getApiActivationGetValidationMetadata(
        query?: GetValidationMetadataQuery,
    ): CancelablePromise<GetValidationMetadataQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Activation/GetValidationMetadata',
            query: {
                'query': query,
            },
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupFrequenciesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupFrequencies(
        requestBody?: GetAdGroupFrequencyQuery,
    ): CancelablePromise<GetAdGroupFrequenciesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupFrequencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationAddAdGroupFrequencies(
        requestBody?: AddAdGroupFrequenciesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/AddAdGroupFrequencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationDeleteAdGroupsFrequencies(
        requestBody?: DeleteAdGroupFrequenciesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteAdGroupsFrequencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationUpdateAdGroupFrequencies(
        requestBody?: UpdateAdGroupFrequenciesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/UpdateAdGroupFrequencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetInventoryListsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetInventoryLists(
        requestBody?: GetInventoryListsQuery,
    ): CancelablePromise<GetInventoryListsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetInventoryLists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupInventoryListsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupInventoryLists(
        requestBody?: GetAdGroupInventoryListsQuery,
    ): CancelablePromise<GetAdGroupInventoryListsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupInventoryLists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetPublishersForAdGroupTargetingQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetPublishersForAdGroupTargeting(
        requestBody?: GetPublishersForAdGroupTargetingQuery,
    ): CancelablePromise<GetPublishersForAdGroupTargetingQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetPublishersForAdGroupTargeting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetTotalCpmQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetTotalCpm(
        requestBody?: GetTotalCpmQuery,
    ): CancelablePromise<GetTotalCpmQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetTotalCpm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationUpdateAdGroupCreatives(
        requestBody?: UpdateAdGroupCreativesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/UpdateAdGroupCreatives',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationDeleteAdGroupCreatives(
        requestBody?: DeleteAdGroupCreativesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/DeleteAdGroupCreatives',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupCreativesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupCreatives(
        requestBody?: GetAdGroupCreativesQuery,
    ): CancelablePromise<GetAdGroupCreativesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupCreatives',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetListOfCreativesAvailableForAdGroupQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetListOfCreativesAvailableForAdGroup(
        requestBody?: GetListOfCreativesAvailableForAdGroupQuery,
    ): CancelablePromise<GetListOfCreativesAvailableForAdGroupQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetListOfCreativesAvailableForAdGroup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkAdGroupCreatives(
        requestBody?: LinkAdGroupCreativesCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkAdGroupCreatives',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationLinkAdGroupCreativesLevers(
        requestBody?: LinkAdGroupCreativesLeversCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/LinkAdGroupCreativesLevers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiActivationSetMediaBidStrategy(
        requestBody?: SetMediaBidStrategyCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetMediaBidStrategy',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupMediaBidStrategyQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupMediaBidStrategy(
        requestBody?: GetAdGroupMediaBidStrategyQuery,
    ): CancelablePromise<GetAdGroupMediaBidStrategyQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupMediaBidStrategy',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupDaypartingQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupDayparting(
        requestBody?: GetAdGroupDaypartingQuery,
    ): CancelablePromise<GetAdGroupDaypartingQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupDayparting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSetAdGroupDayparting(
        requestBody?: SetAdGroupDaypartingCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetAdGroupDayparting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSetAdGroupPublishers(
        requestBody?: SetAdGroupPublishersCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetAdGroupPublishers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignsStatisticsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignsStatistics(
        requestBody?: GetCampaignsStatisticsQuery,
    ): CancelablePromise<GetCampaignsStatisticsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignsStatistics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAccountLevelStatsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAccountLevelStats(
        requestBody?: GetAccountLevelStatsQuery,
    ): CancelablePromise<GetAccountLevelStatsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAccountLevelStats',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupStatisticsForCampaignQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupStatisticsForCampaign(
        requestBody?: GetAdGroupStatisticsForCampaignQuery,
    ): CancelablePromise<GetAdGroupStatisticsForCampaignQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupStatisticsForCampaign',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupStatisticsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupStatistics(
        requestBody?: GetAdGroupStatisticsQuery,
    ): CancelablePromise<GetAdGroupStatisticsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupStatistics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupPublishersQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupPublishers(
        requestBody?: GetAdGroupPublishersQuery,
    ): CancelablePromise<GetAdGroupPublishersQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupPublishers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiActivationMakeAdGroupFavorite(
        id: number,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Activation/MakeAdGroupFavorite/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public putApiActivationMakeCampaignFavorite(
        id: number,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Activation/MakeCampaignFavorite/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignOverviewFlightScheduleDataQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignOverviewFlightScheduleData(
        requestBody?: GetCampaignOverviewFlightScheduleDataQuery,
    ): CancelablePromise<GetCampaignOverviewFlightScheduleDataQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignOverviewFlightScheduleData',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignOverviewDeliveryDataQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignOverviewDeliveryData(
        requestBody?: GetCampaignOverviewDeliveryDataQuery,
    ): CancelablePromise<GetCampaignOverviewDeliveryDataQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignOverviewDeliveryData',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignOverviewStatsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignOverviewStats(
        requestBody?: GetCampaignOverviewStatsQuery,
    ): CancelablePromise<GetCampaignOverviewStatsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignOverviewStats',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignOverviewLeaderboardQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignOverviewLeaderboard(
        requestBody?: GetCampaignOverviewLeaderboardQuery,
    ): CancelablePromise<GetCampaignOverviewLeaderboardQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignOverviewLeaderboard',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetUserAdGroupsQuickStatsFiltersQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetUserAdGroupsQuickStatsFilters(
        requestBody?: GetUserAdGroupsQuickStatsFiltersQuery,
    ): CancelablePromise<GetUserAdGroupsQuickStatsFiltersQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetUserAdGroupsQuickStatsFilters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationSetUserAdGroupsQuickStatsFilters(
        requestBody?: SetUserAdGroupsQuickStatsFiltersCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SetUserAdGroupsQuickStatsFilters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignsStatisticsCsv(
        requestBody?: GetCampaignsStatisticsCsvQuery,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignsStatisticsCsv',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupStatisticsCsv(
        requestBody?: GetAdGroupStatisticsCsvQuery,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupStatisticsCsv',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns binary OK
     * @throws ApiError
     */
    public postApiActivationGetAdGroupStatisticsForCampaignCsv(
        requestBody?: GetAdGroupStatisticsForCampaignCsvQuery,
    ): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAdGroupStatisticsForCampaignCsv',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetPlBrandsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetPlBrands(
        requestBody?: GetPlBrandsQuery,
    ): CancelablePromise<GetPlBrandsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetPlBrands',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetTherapeuticAreasQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetTherapeuticAreas(
        requestBody?: GetTherapeuticAreasQuery,
    ): CancelablePromise<GetTherapeuticAreasQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetTherapeuticAreas',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetP360BrandsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetP360Brands(
        requestBody?: GetP360BrandsQuery,
    ): CancelablePromise<GetP360BrandsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetP360Brands',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetPlBrandCodesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetPlBrandCodes(
        requestBody?: GetPlBrandCodesQuery,
    ): CancelablePromise<GetPlBrandCodesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetPlBrandCodes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetP360BrandCodesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetP360BrandCodes(
        requestBody?: GetP360BrandCodesQuery,
    ): CancelablePromise<GetP360BrandCodesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetP360BrandCodes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetFeasibilityResultQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetFeasibilityResult(
        requestBody?: GetFeasibilityResultQuery,
    ): CancelablePromise<GetFeasibilityResultQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetFeasibilityResult',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetLocationsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetLocations(
        requestBody?: GetLocationsQuery,
    ): CancelablePromise<GetLocationsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetLocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetSourceAdGroupsQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetSourceAdGroups(
        requestBody?: GetSourceAdGroupsQuery,
    ): CancelablePromise<GetSourceAdGroupsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetSourceAdGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetSourceAdGroupsValidateQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetSourceAdGroupsValidate(
        requestBody?: GetSourceAdGroupsValidateQuery,
    ): CancelablePromise<GetSourceAdGroupsValidateQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetSourceAdGroupsValidate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetCampaignDetailsDataQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetCampaignDetailsData(
        requestBody?: GetCampaignDetailsDataQuery,
    ): CancelablePromise<GetCampaignDetailsDataQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetCampaignDetailsData',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiActivationBulkResend(
        requestBody?: BulkResendCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/BulkResend',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns SearchAudiencesQueryResult OK
     * @throws ApiError
     */
    public postApiActivationSearchAudiences(
        requestBody?: SearchAudiencesQuery,
    ): CancelablePromise<SearchAudiencesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/SearchAudiences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAudiencesByCategoryQueryResult OK
     * @throws ApiError
     */
    public postApiActivationGetAudiencesByCategory(
        requestBody?: GetAudiencesByCategoryQuery,
    ): CancelablePromise<GetAudiencesByCategoryQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Activation/GetAudiencesByCategory',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
